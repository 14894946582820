<template>
  <b-container>
    <ResourceList
      :can-create="false"
      :can-select-many="false"
      :data="dataCompleted"
      :has-search="false"
      :is-deleting="isDeleting"
      :is-loading="isLoading"
      :pagination="pagination"
      :search-query="$route.query.query"
      resource="completed item"
      title="Completed To-Dos"
      @paginate="onPaginate"
      @search="onSearch">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              {{ item.todo }}
              <span class="d-flex text-muted small mt-2">
                Completed By: {{ item.user.name }} {{ moment(item.completed).format('DD-MMM-YYYY') }}
              </span>
            </p>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import momentMixin from "../../mixins/momentMixin"
import {mapActions, mapGetters} from "vuex"

export default {
  mixins: [currentUser, momentMixin],
  created() {
    this.fetchManyCompleted(this.$route.query)
  },
  components: {
    ResourceList,
  },

  data: function () {
    return {
      user: {},
    };
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("todos", ["dataCompleted", "isDeleting", "isLoading", "pagination"]),
  },
  methods: {
    ...mapActions("todos", ["deleteMany", "fetchManyCompleted"]),

    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchManyCompleted(this.$route.query)
    },

    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

  },
}
</script>
