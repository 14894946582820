<template>
  <router-view/>
</template>

<script>
import userModule from "../../store/users";

export default {
  beforeCreate() {
    this.$store.registerModule("users", userModule)
  },
  destroyed() {
    this.$store.unregisterModule("users")
  }
}
</script>
